<template>
  <main class="Financial">
    <TheSubHeader
      ref="theSubHeader"
      icon="money"
      title="Financeiro"
      page-title-tag="Financial History Upload Sheets"
      type="routerTabs"
      organizer-gap="1rem"
    >
      <template #afterButtons>
        <BaseButton
          icon="upload"
          color="primary-base"
          label="Upload de Planilha"
          class="operation-button"
          :track="
            trackSubheaderButtonString(
              'Financial History Upload Sheets',
              'Upload'
            )
          "
          @click="openUploadModal()"
        />

        <BaseButton
          v-if="
            permissions &&
              permissions.historyUploadSheets &&
              permissions.historyUploadSheets.uploadMisplacementSpreadsheet
          "
          icon="upload"
          color="primary-base"
          label="Upload de Planilha Extravio"
          class="operation-button"
          :track="
            trackSubheaderButtonString(
              'Financial History Upload Sheets',
              'Upload Misplacement'
            )
          "
          @click="openUploadModal('misplacement')"
        />

        <BaseButton
          v-if="
            permissions &&
              permissions.editVehicles &&
              permissions.editVehicles.isVisible
          "
          icon="truck-delivered"
          color="primary-base"
          label="Tipo de Veículo"
          class="car-type-button"
          :track="
            trackSubheaderButtonString(
              'Financial History Upload Sheets',
              'Financial Edit Vehicle'
            )
          "
          @click="goToEditVehicle()"
        />

        <BaseButton
          icon="status"
          color="primary-base"
          label="Custo de operação"
          class="operation-button"
          :track="
            trackSubheaderButtonString(
              'Financial History Upload Sheets',
              'Operation Cost'
            )
          "
          @click="openModalOperation()"
        />
      </template>

      <template #filter>
        <section class="Financial__subheaderTab">
          <!-- Date filter -->
          <BaseDatePicker
            v-model="formattedDateRange"
            label="Intervalo entre datas (max. 15 dias)"
            :max-range="15"
            @input="handlerHistoryRequest"
          />

          <!-- Status filter -->
          <BaseTreeSelect
            v-model="filters.status"
            name="Status"
            label="Status"
            placeholder="Selecione"
            :enable-paste="false"
            :select-options="statusList"
            @input="handlerHistoryRequest"
          />

          <!-- Type filter -->
          <BaseTreeSelect
            v-model="filters.type"
            name="Tipo"
            label="Tipo"
            placeholder="Selecione"
            :enable-paste="false"
            :select-options="typeList"
            @input="handlerHistoryRequest"
          />
        </section>
      </template>
    </TheSubHeader>

    <section class="Financial__content">
      <DataTable
        :header="paymentUploadHistory.data.headers"
        :table="paymentUploadHistory.data.content"
        class="Financial__table"
        @download="download"
        @remove="remove"
        @seeErrors="seeErrors"
        @edit="editHistoryUpload"
      />

      <Pagination @page="fetchUploadedHistory" @paginate="fetchUploadedHistory">
        <div class="Financial__spreadsheet-model">
          <p>
            Precisa do modelo de planilha para enviar para o cliente?
            <span @click="handleOpenModalDownloadSheets">Clique aqui!</span>
          </p>

          <DownloadSpreadsheetModal ref="DownloadSpreadsheet" />
        </div>
      </Pagination>
    </section>

    <DialogErrors ref="showErrors" />

    <Dialog
      v-if="showConfirmDialog"
      warning="Deseja realmente excluir o arquivo?"
      label-cancel="Não"
      label-confirm="Sim"
      @confirm="confirmDeleteFile"
      @cancel="showConfirmDialog = false"
    />

    <!-- Upload Modal -->
    <BaseInputFileGroupModal
      ref="uploadModal"
      :multiple="false"
      :show-formats="false"
      :accept="['.csv']"
      :value="formDataModel"
      @save="handleSave"
    />
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
//Components
import { DataTable, Pagination, Dialog } from '@/components/molecules'
import DialogErrors from '@/views/financial/DialogErrors'
import { TheSubHeader } from '@/components/organisms'
import { BaseDatePicker, BaseTreeSelect, BaseButton } from '@/components/atoms'
import BaseInputFileGroupModal from '@/components/atoms/BaseInputFile/BaseInputFileGroupModal'
import DownloadSpreadsheetModal from '@/views/financial/DownloadSpreadsheetModal'

//Mixins
import FinancialMixin from '@/mixins/financial'
//Api
import { financial } from '@/api'

export default {
  name: 'HistorysUploadSheets',

  components: {
    TheSubHeader,
    BaseDatePicker,
    BaseTreeSelect,
    BaseButton,
    BaseInputFileGroupModal,
    DataTable,
    Pagination,
    DialogErrors,
    Dialog,
    DownloadSpreadsheetModal
  },

  mixins: [FinancialMixin],

  data() {
    return {
      filters: {
        dateRange: {
          init: null,
          end: null
        },
        status: null,
        type: null
      },
      typeList: [
        { id: 1, name: 'Custo' },
        { id: 2, name: 'Extravio' }
      ],
      misplacement: '',
      formDataModel: new FormData(),
      showConfirmDialog: false,
      deleteFileId: null,
      timeOutUpdate: 10 * 1000,
      time: null,
      updating: false,
      dialogHeaders: {
        id: {
          id: 'id',
          isVisible: false,
          label: 'id',
          method: false,
          param: 'id'
        },
        field: {
          id: 'field',
          isVisible: true,
          label: 'Campo',
          method: false,
          param: 'field'
        },
        message: {
          id: 'message',
          isVisible: true,
          label: 'Erro',
          method: false,
          param: 'message'
        }
      },
      modalTable: {
        headers: {
          id: { id: 'id', isVisible: false, label: 'ID', param: 'id' },
          name: {
            id: 'name',
            isVisible: true,
            label: 'Planilhas Padrões',
            param: 'name'
          },
          actions: {
            actions: true,
            id: 'actions',
            isVisible: true,
            label: '',
            param: 'actions'
          }
        },
        content: [
          {
            id: 0,
            name: 'Driver Location',
            actions: [{ action_id: 'download', has_permission: true }]
          },
          {
            id: 1,
            name: 'Olist',
            actions: [{ action_id: 'download', has_permission: true }]
          },
          {
            id: 2,
            name: 'Transferência',
            actions: [{ action_id: 'download', has_permission: true }]
          },
          {
            id: 4,
            name: 'Extravio',
            actions: [{ action_id: 'download', has_permission: true }]
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
      paymentUploadHistory: state => state.financial.paymentUploadHistory,
      permissions: state => state.auth.permissions
    })
  },

  mounted() {
    this.clearPagination(20)
    this.fetchUploadedHistory()
    this.initStatusList()
  },

  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading',
      clearPagination: 'pagination/clearPagination',
      fetchPaymentUploadHistory: 'financial/fetchPaymentUploadHistory',
      setPagination: 'pagination/setPagination'
    }),

    async fetchUploadedHistory(loading = true) {
      if (loading) {
        this.setLoading(true)
      }

      const params = {
        page: this.page,
        paginate: this.paginate,
        obj: {
          date_start: this.filters?.dateRange?.init,
          date_end: this.filters?.dateRange?.end,
          status_id: this.filters?.status,
          type: this.filters?.type
        }
      }

      await this.fetchPaymentUploadHistory(params).then(() => {
        this.setPagination(this.paymentUploadHistory)
        if (
          JSON.stringify(this.paymentUploadHistory.data.content).indexOf(
            'Andamento'
          ) > 0 &&
          this.$route.name === 'historyUploadSheets'
        ) {
          if (!this.updating) {
            this.updating = true
            this.time = setTimeout(() => {
              this.fetchUploadedHistory(false)
              this.updating = false
            }, this.timeOutUpdate + this.time)
            this.setPagination(this.paymentUploadHistory)
          }
        } else {
          clearTimeout(this.time)
          this.setPagination(this.paymentUploadHistory)
        }
      })
    },

    async confirmDeleteFile() {
      this.setLoading(true)
      await financial.deleteUploadedFile(
        this.deleteFileId,
        async () => {
          await this.fetchUploadedHistory()
          this.showConfirmDialog = false
          this.$toast.success('Arquivo excluído', { position: 'bottom-center' })
        },
        e => e
      )
      this.setLoading(false)
    },

    async download(file) {
      this.setLoading(true)
      await financial.downloadUploadedFile(
        file.id,
        r => r,
        e => e
      )
      this.setLoading(false)
    },

    remove(file) {
      this.deleteFileId = file.id
      this.showConfirmDialog = true
    },

    async seeErrors(errors) {
      this.setLoading(true)
      await financial.fetchPaymentUploadFileErrors(
        errors.id,
        res => {
          this.$refs.showErrors?.open(this.dialogHeaders, res, errors.field)
        },
        e => e,
        () => this.setLoading(false)
      )
    },

    editHistoryUpload(data) {
      this.$router.push(`edit-upload/${data.id}`)
    },

    handleOpenModalDownloadSheets() {
      this.$refs.DownloadSpreadsheet?.open(this.modalTable)
    },

    async handlerHistoryRequest() {
      this.clearPagination(20).then(() => this.fetchUploadedHistory(true))
    },

    openUploadModal(type) {
      if (type === 'misplacement') {
        this.misplacement = true
      }
      this.$refs.uploadModal.open()
    },

    handleSave(formDataModel, formDataFiles) {
      this.formDataModel = formDataModel
      this.submitPaymentClosingFile(formDataFiles)
    },

    async submitPaymentClosingFile(data) {
      this.setLoading(true)
      let formData = new FormData()
      const obj = data.find(el => el.file)
      formData.append('file', obj.file)

      let endpoint = 'uploadPaymentFile'

      if (this.misplacement) {
        endpoint = 'uploadMisplacementFile'
      }

      await financial[endpoint](
        formData,
        async () => {
          await this.handlerHistoryRequest()
          this.$toast.success(
            'Planilha importada! Verifique na lista de histórico de uploads se foram encontrados erros durante o processamento.',
            { position: 'bottom-center' }
          )
        },
        e => {
          const error = e.validation?.file[0].length
            ? e.validation?.file[0]
            : 'Ocorreu um erro, tente novamente!'
          this.$toast.error(error, { position: 'bottom-center' })
        },
        () => {
          this.setLoading(false)
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.Financial {
  &__subheaderTab {
    display: grid;
    align-items: end;
    grid-gap: 1rem;
    grid-template-columns: 250px 250px 250px;

    @media #{$mobile-view} {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  &__content {
    margin: 0 auto;
    max-width: 1180px;
    width: 100%;
    max-width: 1180px;
    padding: 0 0 3rem 0;

    @media (max-width: $viewport-lg) {
      padding: 0 1rem 3rem 1rem;
    }
  }

  &__table {
    width: 100%;
  }

  &__spreadsheet-model {
    width: 100%;
    padding-top: 2rem;

    p {
      text-align: center;
      font-size: $font-size-mini;
      padding: 0 20px;
    }

    span {
      cursor: pointer;
      color: $color-primary-base;
    }
  }
}
</style>
